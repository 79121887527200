import React, { useState } from 'react'
import Fade from 'react-reveal/Fade'
import PageHeader from '../components/glossary/PageHeader'
import Grid from '../components/glossary/Grid'
import Layout from '../components/common/Layout'
import CTA from '../components/common/CTA'

const GlossaryPage = ({ data }) => {
  const pages = data.formats.nodes
  return (
    <Layout
      title="GIS Glossary - Definitions & FAQs"
      meta={{
        description:
          'Comprensive list of GIS terms and definitions you need to deepen your understanding of spatial data science and location intelligence.',
        title: 'GIS Glossary - Definitions & FAQs for your geospatial analysis',
        absoluteImageUrl: 'https://www.atlas.co/images/glossary.png',
        type: 'website'
      }}
    >
      <main>
        <Fade up duration={1000} delay={0} distance="30px">
          <PageHeader />
        </Fade>
        <Fade up duration={1000} delay={0} distance="30px">
          <Grid pages={pages} />
        </Fade>
        <Fade up duration={1000} delay={0} distance="30px">
          <CTA text="Ready to level up your map-making process?" />
        </Fade>
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    formats: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___title] }
      filter: { fileAbsolutePath: { regex: "/glossary/" } }
      limit: 200
    ) {
      nodes {
        id
        frontmatter {
          title
          meta {
            title
            description
            absoluteImageUrl
          }
        }
      }
    }
  }
`

export default GlossaryPage
